.custom-scrollbar-note,
.custom-scrollbar-note-timeline {
  overflow-y: scroll;
}

.custom-scrollbar-note::-webkit-scrollbar,
.custom-scrollbar-note-timeline::-webkit-scrollbar {
  width: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-scrollbar-note:hover::-webkit-scrollbar,
.custom-scrollbar-note-timeline:hover::-webkit-scrollbar {
  opacity: 1;
}

.custom-scrollbar-note::-webkit-scrollbar-thumb,
.custom-scrollbar-note-timeline::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}

.custom-scrollbar-note::-webkit-scrollbar-track,
.custom-scrollbar-note-timeline::-webkit-scrollbar-track {
  background: white;
}
