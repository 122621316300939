.btn {
    width: 8rem;
    font-family: inherit;
    font-size: 20px;
    background: royalblue;
    color: white;
    padding: 0.5em 1em;
    padding-left: 0.9em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.2s;
    cursor: pointer;
  }
  
  .btn span {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
  }
  
  .btn svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
  }
  
  .btn:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
  }
  
  .btn:hover svg {
    transform: translateX(1.2em) rotate(45deg) scale(1.1);
  }
  
  .btn:hover span {
    transform: translateX(5em);
  }
  
  .btn:active {
    transform: scale(0.95);
  }
  
  @keyframes fly-1 {
    from {
      transform: translateY(0.1em);
    }
  
    to {
      transform: translateY(-0.1em);
    }
  }
  

  /*  --------------------- scrollbar --------------------- */
  /* Style the scrollbar for a specific class */
.custom-scrollbar::-webkit-scrollbar {
    width: 6px; /* Adjust the width of the scrollbar */
  }
.custom-scrollbar-timeline::-webkit-scrollbar {
    width: 0px; /* Adjust the width of the scrollbar */
  }
  
  /* Handle of the scrollbar */
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #3A3D89; /* Color of the scrollbar handle */
    border-radius: 0px; /* Roundness of the scrollbar handle */
  }
  
  /* Track of the scrollbar */
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #EB1F36; /* Color of the scrollbar track */
  }
  